import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "apps.project",
    path: `${APP_PREFIX_PATH}/apps/project`,
    component: React.lazy(() => import("views/app-views/apps/project")),
  },
  {
    key: "apps.project.list",
    path: `${APP_PREFIX_PATH}/apps/project/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/project/project-list/ProjectList")
    ),
  },
  {
    key: "apps.project.scrumboard",
    path: `${APP_PREFIX_PATH}/apps/project/scrumboard`,
    component: React.lazy(() =>
      import("views/app-views/apps/project/scrumboard")
    ),
  },

  // region

  {
    key: "apps.regions",
    path: `/region`,
    component: React.lazy(() =>
      import("views/app-views/apps/regions/region-list")
    ),
  },
  {
    key: "apps.regions.region-add",
    path: `/region/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/regions/region-add")
    ),
  },
  {
    key: "apps.regions.region-edit",
    path: `/region/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/regions/region-edit")
    ),
  },
  {
    key: "apps.regions.region-list",
    path: `/region/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/regions/region-list")
    ),
  },

  // Organization
  {
    key: "apps.organizations",
    path: `/organization`,
    component: React.lazy(() => import("views/app-views/apps/organizations/organization-list/index")),
  },
  {
    key: "apps.organizations.add-organization",
    path: `/organization/add`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/add-organization")
    ),
  },
  {
    key: "apps.organizations.edit-organization",
    path: `/organization/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/edit-organization")
    ),
  },
  {
    key: "apps.organizations.organization-list",
    path: `/organization/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/organization-list")
    ),
  },

  // Role
  {
    key: "apps.roles",
    path: `/role`,
    component: React.lazy(() => import("views/app-views/apps/roles/role-list/index")),
  },
  {
    key: 'apps.roles.roles-detail',
    path: `/o/:uuid/role/details/:id`,
    component: React.lazy(() => import('views/app-views/apps/roles/roleDetails')),
    menuCode: 'roles'
},
  {
    key: "apps.roles.role-list",
    path: `/role/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/roles/role-list/index")
    ),
  },

  // invoice
  {
    key: "apps.invoices",
    path: `/invoice`,
    component: React.lazy(() => import("views/app-views/apps/invoices/invoice-list")),
  },
  // {
  //   key: "apps.invoices.add-invoice",
  //   path: `${APP_PREFIX_PATH}/invoices/add`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/invoices/add-organization")
  //   ),
  // },
  // {
  //   key: "apps.organizations.edit-organization",
  //   path: `${APP_PREFIX_PATH}/organizations/edit/:id`,
  //   component: React.lazy(() =>
  //     import("views/app-views/apps/organizations/edit-organization")
  //   ),
  // },
  {
    key: "apps.invoices.invoice-list",
    path: `/invoice/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/invoices/invoice-list")
    ),
  },

  {
    key: "apps.analytics",
    path: `/analytic`,
    component: React.lazy(() => import("views/app-views/apps/analytics/analytic-list")),
  },
  {
    key: "apps.analytics.analytic-list",
    path: `/analytic/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/analytics/analytic-list")
    ),
  },

// Email Template 
  {
    key: "apps.templates",
    path: `/email-template`,
    component: React.lazy(() => import("views/app-views/apps/templates/template-list")),
  },
  {
    key: "apps.templates.edit-templates",
    path: `/email-template/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/apps/templates/template-edit")
    ),
  },
  {
    key: "apps.templates.template-list",
    path: `/email-template`,
    component: React.lazy(() =>
      import("views/app-views/apps/templates/template-list")
    ),
  },

  {
    key: "apps.reports",
    path: `/report`,
    component: React.lazy(() => import("views/app-views/apps/reports/report-list")),
  },
  {
    key: "apps.reports.report-list",
    path: `/report/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/reports/report-list")
    ),
  },

  {
    key: "apps.log-files",
    path: `/superadmin-log`,
    component: React.lazy(() => import("views/app-views/apps/log-files/log-list")),
  },
  {
    key: "apps.log-files.log-list",
    path: `/log-file/list`,
    component: React.lazy(() =>
      import("views/app-views/apps/log-files/log-list")
    ),
  },

  {
    key: "apps.organizations.orders",
    path: `/organization/orders`,
    component: React.lazy(() =>
      import("views/app-views/apps/organizations/orders")
    ),
  },

// Super Admin User 
  {
    key: 'apps.users',
    path: `/superadmin-user`,
    component: React.lazy(() => import('views/app-views/apps/superadminusers/user-list/index')),
},
{
    key: 'apps.users.user-add',
    path: `/superadmin-user/add`,
    component: React.lazy(() => import('views/app-views/apps/superadminusers/user-add')),
},
{
    key: 'apps.users.user-edit',
    path: `/superadmin-user/edit/:id`,
    component: React.lazy(() => import('views/app-views/apps/superadminusers/user-edit')),
},
{
    key: 'apps.users.user-list',
    path: `/superadmin-user/list`,
    component: React.lazy(() => import('views/app-views/apps/superadminusers/user-list')),
},

  {
    key: "pages",
    path: `${APP_PREFIX_PATH}/pages`,
    component: React.lazy(() => import("views/app-views/pages")),
  },
  {
    key: "pages.profile",
    path: `${APP_PREFIX_PATH}/pages/profile`,
    component: React.lazy(() => import("views/app-views/pages/profile")),
  },
  {
    key: "pages.invoice",
    path: `${APP_PREFIX_PATH}/pages/invoice`,
    component: React.lazy(() => import("views/app-views/pages/invoice")),
  },
  {
    key: "pages.setting",
    path: `${APP_PREFIX_PATH}/pages/setting/*`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },
  {
    key: "pages.user-list",
    path: `${APP_PREFIX_PATH}/pages/user-list`,
    component: React.lazy(() => import("views/app-views/pages/user-list")),
  },
];
