import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    user: {}
}

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
        changeProfile: (state, action) => {
			state.user = action.payload.user
		}
	},
})

export const { changeProfile } = profileSlice.actions

export default profileSlice.reducer